


















































































































































import Vue from "vue";
import firebase from "@/plugins/firebase";
import "@/types";
import Confirm from "@/components/Confirm.vue";

export default Vue.extend({
  components: {
    Confirm
  },

  metaInfo() {
    return {
      title: "Sipariş No: " + this.order.id
    };
  },

  data: () => ({
    order: {} as Order,
    user: {} as User
  }),

  methods: {
    async getOrder() {
      try {
        // Get order
        const orderRef = await firebase
          .firestore()
          .doc(`/apps/kahev-akademi/orders/${this.$route.params.id}`)
          .get();

        if (orderRef.exists) {
          this.order = {
            id: orderRef.id,
            uid: orderRef.data()?.uid,
            name: orderRef.data()?.name,
            items: orderRef.data()?.items,
            status: orderRef.data()?.status,
            dateCreated: orderRef.data()?.dateCreated.toDate(),
            amount: orderRef.data()?.amount,
            address: orderRef.data()?.address,
            ccHolder: orderRef.data()?.ccHolder,
            city: orderRef.data()?.city,
            postCode: orderRef.data()?.postCode,
            tckn: orderRef.data()?.tckn,
            billType: orderRef.data()?.billType,
            ccNumber: orderRef.data()?.ccNumber,
            corpName: orderRef.data()?.corpName,
            installmentCount: orderRef.data()?.installmentCount,
            paymentDetails: orderRef.data()?.paymentDetails,
            paymentType: orderRef.data()?.paymentType,
            statusMessage: orderRef.data()?.statusMessage,
            taxNumber: orderRef.data()?.taxNumber
          };

          const userQs = await firebase
            .firestore()
            .collection("/apps/kahev-akademi/users")
            .where("uid", "==", this.order.uid)
            .get();

          const userRef = userQs.docs[0];

          this.user = {
            id: this.order.uid,
            firstName: userRef.data()?.firstName,
            lastName: userRef.data()?.lastName,
            displayName: `${userRef.data()?.firstName} ${
              userRef.data()?.lastName
            }`,
            email: userRef.data()?.email,
            phone: userRef.data()?.phone,
            roles: userRef.data()?.roles
          };
        }
      } catch (err) {
        this.$notify({
          type: "error",
          title: "Hata!",
          text: `Sipariş bilgilerini alınamadı.`
        });

        throw new Error(`Sipariş bilgileri alınamadı. ${err.message}`);
      }
    },

    async confirmPayment() {
      const $confirm = this.$refs.confirm as InstanceType<typeof Confirm>;
      const confirmation = await $confirm.open(
        "Emin misiniz?",
        "Para transferini onaylayarak eğitimleri kullanıcıya açmak istediğinizden emin misiniz?",
        {
          color: "red",
          width: 360
        }
      );

      if (!confirmation) return;

      try {
        // Ders kayıtlarını oluştur
        this.order.items.map(async (item: CartItem) => {
          console.log({ uid: this.user.id, courseId: item.id });

          //await this.addCourseReg({ uid: this.user.id, courseId: item.id });
          await this.$store.dispatch("lms/addCourseReg", {
            uid: this.user.id,
            courseId: item.id
          });
        });

        // Siparişi güncelle
        const orderRef = firebase
          .firestore()
          .doc(`/apps/kahev-akademi/orders/${this.order.id}`);

        orderRef.update({
          status: "success",
          statusMessage: "Para transferi onaylandı."
        });

        // Güncel bilgileri
        this.getOrder();
      } catch (err) {
        this.$notify({
          type: "error",
          title: "Hata!",
          text: "Bir hata oluştu. Para transferi onaylanamadı."
        });

        throw new Error(`Para transferi onaylanamadı. ${err.message}`);
      }
    }
  },

  async mounted() {
    await this.getOrder();
  }
});
